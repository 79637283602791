// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgComputer = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.25 21a.75.75 0 0 1 .75-.75h15a.75.75 0 0 1 0 1.5H2a.75.75 0 0 1-.75-.75M20.25 21a.75.75 0 0 1 .75-.75h1a.75.75 0 0 1 0 1.5h-1a.75.75 0 0 1-.75-.75M2.75 3.75v12.5h18.5V3.75zm-1.5-.15c0-.746.604-1.35 1.35-1.35h18.8c.746 0 1.35.604 1.35 1.35v12.8a1.35 1.35 0 0 1-1.35 1.35H2.6a1.35 1.35 0 0 1-1.35-1.35z"
      clipRule="evenodd"
    />
  </svg>
);
export const ComputerIcon = forwardRef(SvgComputer);
